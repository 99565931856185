(function() {
    'use strict';

    angular.module('ionicProjectUser', [
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicProject',
        'ionicRequest',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicProjectUser').service(
        'ProjectUserListService',
        ProjectUserListService
    );

    ProjectUserListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'LoginService',
        'ProjectService',
        'RequestManagementService'
    ];
    
    function ProjectUserListService(
        DefaultVariableService,
        ListManagementService,
        LoginService,
        ProjectService,
        RequestManagementService
    ) {
        var ProjectUserListService = this;

        ProjectUserListService.getProjectUsers = getProjectUsers;
        function getProjectUsers(options) {
            return ProjectUserListService.loadProjectUsers(options);
        }

        ProjectUserListService.loadProjectUsers = loadProjectUsers;
        function loadProjectUsers(options) {
            return ProjectService.getProject().then(
                function(project) {
                    if (project) {
                        return LoginService.getActiveUser().then(
                            function(activeUser) {
                                if (activeUser) {
                                    options = RequestManagementService.getRequest(options);

                                    options = RequestManagementService.setModel(options, 'users');
                                    options = RequestManagementService.setAction(options, 'get');

                                    options = RequestManagementService.setLimit(options, 10000);

                                    options = RequestManagementService.setParams(
                                        options,
                                        [
                                            'id!=' + activeUser.id,
                                            'Projects.id=' + project.id
                                        ]
                                    );

                                    return ListManager.getMore(options);
                                }

                                return false;
                            }
                        );
                    }

                    return false;
                }

            );
        }

        ProjectUserListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager();

        ProjectUserListService.reset();

        return ProjectUserListService;
    }
})();

(function() {
    'use strict';

    angular.module('ionicProjectUser').controller(
        'ProjectUserListController',
        ProjectUserListController
    );

    ProjectUserListController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'ProjectUserListService'
    ];
    
    function ProjectUserListController(
        DefaultVariableService,
        LoginService,
        ProjectUserListService
    ) {
        var ProjectUserListController = this;

        ProjectUserListController.clear = clear;
        function clear() {
            ProjectUserListService.reset();
            ProjectUserListController.reset();
        }

        ProjectUserListController.forceRefresh = forceRefresh;
        function forceRefresh() {
            ProjectUserListController.clear();
            ProjectUserListController.init();
        }

        ProjectUserListController.loadProjectUsers = loadProjectUsers;
        function loadProjectUsers(options) {
            ProjectUserListController.isLoadingProjectUsers = true;

            return ProjectUserListService.getProjectUsers(options).then(
                function(data) {
                    if (data) {
                        ProjectUserListController.projectUsers = DefaultVariableService.getArray(
                            data.users
                        );
                    }

                    ProjectUserListController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return data;
                }
            ).finally(
                function() {
                    ProjectUserListController.isLoadingProjectUsers = false;
                }
            );
        }

        ProjectUserListController.reset = reset;
        function reset() {
            ProjectUserListController.canLoadMore = true;

            ProjectUserListController.isLoadingProjectUsers = false;

            ProjectUserListController.projectUsers = [];
        }

        ProjectUserListController.init = init;
        function init() {
            ProjectUserListController.reset();
            ProjectUserListController.loadProjectUsers();
        }

        LoginService.register(ProjectUserListController);
    }
})();

(function() {
    'use strict';

    angular.module('ionicProjectUser').directive(
        'ionicProjectUserList',
        ionicProjectUserList
    );

    function ionicProjectUserList() {
        return {
            controller:   'ProjectUserListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<ul><li data-ng-repeat="projectUser in ctrl.projectUsers">{{ projectUser.full_name }} ({{ projectUser.username }})</li><li data-ng-if="ctrl.isLoadingProjectUsers">LOADING...</li><li data-ng-if="!ctrl.canLoadMore && ctrl.projectUsers.length === 0">NO USERS</li><li data-ng-if="!ctrl.canLoadMore">NO MORE USERS</li><div><input type="button" value="MORE" data-ng-disabled="ctrl.isLoadingProjectUsers && !ctrl.canLoadMore" data-ng-click="ctrl.loadProjectUsers()"> <input type="button" value="REFRESH" data-ng-click="ctrl.forceRefresh()"></div></ul>'
        };
    }
})();